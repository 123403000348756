<template>
    <div>
        <h4 v-if="$route.path.includes('report')" class="bold text-uppercase">Nhật ký hoạt động</h4>
        <div class="row form-group align-items-center user-report-manage">
            <div class="col-md-4 col-12">
                <h6 class="bold mb-0">Tìm theo username:</h6>
                <form @submit.prevent="getToolsLogs">
                    <input
                        type="text"
                        name="search_username"
                        id="search_username"
                        placeholder="Nhập username"
                        v-model="search_username"
                    />
                </form>
            </div>
            <div class="col-md-4 col-12 mt-2 mb-2">
                <h6 class="bold mb-0">Tìm theo object_id:</h6>
                <form @submit.prevent="getToolsLogs">
                    <input
                        type="text"
                        name="search_object_id"
                        id="search_object_id"
                        placeholder="Nhập object_id"
                        v-model="search_object_id"
                    />
                </form>
            </div>
            <div class="col-md-4 col-12">
                <h6 class="bold mb-0">Tải tối đa:</h6>
                <select class="form-control select-light" v-model="limitLogs" @change="getToolsLogs()">
                    <option value="100">Load 100 nhật ký</option>
                    <option value="200">Load 200 nhật ký</option>
                    <option value="300">Load 300 nhật ký</option>
                    <option value="500">Load 500 nhật ký</option>
                    <option value="1000">Load 1000 nhật ký</option>
                </select>
            </div>
        </div>
        <datatable :stt="true" :columns="columns" :rows="data"> </datatable>
    </div>
</template>
<script>
import { getToolsLogsByQuery } from "../../api/user"
import datatable from "@/components/datatable/datatable.vue"
import { buildParamQuery, catchError } from "../../helpers"
import { APP_LOADING } from "../../store/types"

export default {
    name: "user-report",
    components: {
        datatable
    },
    data() {
        return {
            search_object_id: "",
            search_username: "",
            limitLogs: 100,
            data: [],
            columns: [
                {
                    label: "Hành Động",
                    field: "action"
                },
                {
                    label: "Đối Tượng",
                    field: "object_id",
                    link_link: true,
                    link: "link"
                },
                {
                    label: "Tool",
                    field: "tool_name"
                },
                {
                    label: "Tiền",
                    field: "coin_html",
                    html: true
                },
                {
                    label: "Note",
                    field: "note_html",
                    html: true
                },
                {
                    label: "Trạng Thái",
                    field: "status_html",
                    html: true
                },
                {
                    label: "Thời Gian Tạo",
                    field: "created_at"
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        routerQuery() {
            return this.$route.query
        },
        routerUsername() {
            return this.routerQuery.username
        },
        routerLimit() {
            return this.routerQuery.limit
        }
    },
    created() {
        this.getToolsLogs({ onload: true })
    },
    methods: {
        getToolsLogs: async function(options = {}) {
            const { routerUsername, routerLimit } = this
            this.$store.commit(APP_LOADING, true)
            const params = {
                username: this.search_username,
                object_id: this.search_object_id,
                limit: this.limitLogs
            }
            const { onload } = options
            if (onload) {
                if (routerUsername) {
                    params.username = routerUsername
                    this.search_username = routerUsername
                }
                if (routerLimit) {
                    params.limit = routerLimit
                    this.limitLogs = parseInt(routerLimit)
                }
            }
            const query = buildParamQuery(params)
            let data = await getToolsLogsByQuery(query)
            if (data.status === 200 && data.success) {
                this.handleDataFromServe(data.data)
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
            this.$store.commit(APP_LOADING, false)
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel == "logs") {
                this.getNotify()
            }
        },
        handleDataFromServe: function(serveData) {
            this.data = serveData.map(log => {
                log.note_html =
                    '<input type="text" class="form-control" style="width: 200px" value="' +
                    log.notes.replace(/"/g, "'") +
                    '"/>'
                log.status_html =
                    parseInt(log.status) === 1
                        ? "<span class='badge badge-pill badge-success'>Thành công</span>"
                        : parseInt(log.status) === 0
                        ? "<span class='badge badge-pill badge-warning'>Đang chạy </span>"
                        : "<span class='badge badge-pill badge-danger'>Thất bại</span>"
                let oldCoin = Number(log.old_coin)
                let newCoin = Number(log.new_coin)
                let changeCoin = Number(log.new_coin) - Number(log.old_coin)
                log.changeCoin = changeCoin
                log.coin_html = "<span class='py-1 badge badge-yellow'>" + oldCoin.toLocaleString("it-IT") + "</span>"
                log.coin_html +=
                    changeCoin >= 0
                        ? " + <span class='py-1 badge badge-success'>" + changeCoin.toLocaleString("it-IT") + "</span>"
                        : " - <span class='py-1 badge badge-danger'>" +
                          (changeCoin * -1).toLocaleString("it-IT") +
                          "</span>"
                log.coin_html +=
                    " = <span class='badge badge-pill badge-primary'>" + newCoin.toLocaleString("it-IT") + "</span>"
                log.actions_html = log.actions ? log.actions.name : log.action
                log.name = this.user ? this.user.name : ""
                return log
            })
        }
    }
}
</script>

<style>
.user-report-manage input {
    border: none;
    padding: 7px;
    width: 100%;
}

.user-report-manage select {
    padding: 7px !important;
}
</style>
